<template>
    <div class="flex">
        <div class="w-1/12"></div>
        <div class="w-10/12">
            <menu-row class="p-3"></menu-row>

            <banner-slider v-if="event && event.imageSliderLayout === 'banner'" :images="event.slider_images"></banner-slider>

            <div class="flex mt-4">
                <div class="w-3/12"></div>
                <div class="w-6/12">
                    <div class="flex">
                        <div class="w-2/3">
                            <span v-html="_eventDescription"></span>
                        </div>
                        <div class="w-1/3 border-solid border-2 border-black-900">
                            <h3 class="pl-2 pt-2 font-bold">{{ event ? event.title : null }}</h3>
                            <h5 class="pl-2">{{ _eventStartDate }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import axios from 'axios'
  import moment from 'moment'

  import Menu from './EventInfoPartials/Menu.vue'
  import BannerSlider from './EventInfoPartials/BannerSlider.vue'

  export default {
    name: 'EventInfo',
    components: {
      BannerSlider,
      'menu-row': Menu,
    },
    created() {
      let event_id = this.$route.params.id
      axios.get('event/' + event_id).then(response => {
        this.event = response.data.data
      })
    },
    data() {
      return {
        event: null,
      }
    },
    computed: {
      _eventStartDate() {
        return this.event ? moment(this.event.startDateString).format(this.event.dateFormat.js) : null
      },
      _eventDescription() {
        return this.event ? this.event.description?.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '<br />') : null
      }
    }
  }
</script>