<template>
    <div id="banner-slider" ref="slider">
        <transition-group tag="div" class="img-slider" name="slide">
            <div v-for="index in [imageIndex]" :key="index">
                <img v-if="images[index]" :src="images[index].url"/>
            </div>
        </transition-group>
    </div>
</template>
<script>
  export default {
    props: {
      images: {
        required: true
      }
    },
    mounted() {
      let maxIndex = this.images.length - 1

      setInterval(() => {
        this.imageIndex = this.imageIndex === maxIndex ? 0 : this.imageIndex + 1;
      }, 3000);

      let sliderWidth = this.$refs.slider.clientWidth
      let sliderHeight = sliderWidth * (3 / 16)
      this.$refs.slider.style.height = sliderHeight + 'px'
    },
    data() {
      return {
        imageIndex: 0,
      }
    }
  }
</script>
<style lang="scss">
    #banner-slider {
        width: 100%;

        .slide-leave-active,
        .slide-enter-active {
            transition: 1s;
        }
        .slide-enter {
            transform: translate(100%, 0);
        }
        .slide-leave-to {
            transform: translate(-100%, 0);
        }

        .img-slider {
            overflow: hidden;
            position: relative;
            height: 100%;
            width: 100%;

            img {
                width: 100%;
            }
        }

        .img-slider img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
    }
</style>