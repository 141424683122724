<template>
    <div id="event-menu">
        <div class="menu-item">
            <a href="#">{{ $t('Information') }}</a>
        </div>
        <div class="menu-item">
            <router-link class="btn btn-primary" :to="{name: 'event.registration', 'params': {id: this.$route.params.id}}">{{ $t('registerToTheEvent') }}</router-link>
        </div>
    </div>
</template>
<script>
  export default {}
</script>
<style lang="scss">
    #event-menu {

        width: 100%;
        display: flex;
        justify-content: flex-end;

        .menu-item {
            display: flex;
            align-items: center;
            margin-left:10px;
        }
    }

</style>